import React, {useState} from 'react';
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Section, SectionPageTitle } from "../components/section"
import { useFlexSearch } from 'react-use-flexsearch'
import ProductCard from '../components/ProductCard';

const SearchList = styled.div`
  margin:0 2rem;
`
const SearchItem = styled.div`
    .media-card{
        display:flex;
        flex-wrap:wrap;
        align-items: flex-start;
        margin-left:-15px;
        margin-right:-15px;
        .media-card-thumb{
            position: relative;
            width: 100%;
            min-height: 1px;
            padding:0 15px;
            @media (min-width:576px) {
                flex: 0 0 41.666667%;
                max-width: 41.666667%;
            }
            @media (min-width:1200px) {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
        }
        .media-card-body{
            background-color:transparent;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding:0 15px;
            @media (min-width:576px) {
                flex: 0 0 58.333333%;
                max-width: 58.333333%;
            }
            @media (min-width:1200px) {
                flex: 0 0 66.666667%;
                max-width: 66.666667%;
            }
            .media-card-info{
                margin-top:0;
            }
        }
    }
`
const SearchForm = styled.div`
  border-radius: 6px;
  width: 100%;
  max-width: 260px;
  margin:0 auto 30px auto;
  @media (min-width:1200px) {
    margin:0 auto 40px auto;
  }
  @media (min-width:1600px) {
    margin:0 auto 60px auto;
  }
  input {
    width: 100%;
  }
  .input-icon{
    > .form-control{
        @media (min-width: 1200px){
            padding: 10px 15px;
        }
        @media (min-width: 1600px){
            padding: 15px;
        }

        &::placeholder {
            color:#004485;
        }
        &:focus,
        &:active {
            &::placeholder {
            color:#004485;
            }
        }
    }
    .icon {
        width:45px;
        > svg{
          fill:#4815D6;
          width:20px;
        }    
    }
    &.right {
        > .form-control{
            padding-right: 45px;
            padding-left: 15px;
        }
    }
  }
`

const Container = styled.div`
  margin: 160px auto 40px;
  max-width: 1380px;
  width: 100%;
`
const NoResult = styled.div`
  text-align: center;
`

const SearchPage = ({ data, location }) => {
  const [query, setQuery] = useState(location?.state?.searchKey)
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store
  const handleChange = (e) => {
    setQuery(e.target.value);
  }
  const results = useFlexSearch(query, index, store)
  return (
    <Layout hideSearch location={location}>
      <Seo title="Search Result" description="Search Result" />
      <Section ept="80px" epb="80px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff" className="section-search" >
        <Container className="container">
          <SectionPageTitle textAlign="center">Search Results</SectionPageTitle>
          <SearchForm className='search-form'>
            <form>
              <input
                className="form-control"
                type="text"
                placeholder="Search..."
                id="search"
                name="search"
                value={query ? query : ""}
                onChange={(e) => handleChange(e)}
              />
            </form>
          </SearchForm>
          <SearchList className="search-list">               
            {results.length > 0 ? results.map((item) =>
              <ProductCard data={item.data} />
            ) : (
            <NoResult>                
              <h3>No Results Found</h3>
              <p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p>
            </NoResult>
            )}
          </SearchList>

        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query SearchPageQuery{
    localSearchPages {
      index
      store
    }
  }
`